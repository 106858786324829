<template>
  <div>
    <h4>CITAS</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      :exeGetChildAfterMount="false"
    >
      <template slot="top">
        <div class="mb-2" v-show="create">
          <button class="btn btn-success" @click="$refs.elModalSetEmp.show()">
            <i class="fa fa-plus"></i> Nueva Cita
          </button>
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>CLIENTE</th>
            <!-- <th>C#</th> -->
            <th>TRATAMIENTO</th>
            <th>FUNCIONARIO</th>
            <th></th>
            <th>FECHA</th>
            <th>ESTADO</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td>{{ l.client_name }}</td>
            <!-- <td>{{ l.client_code }}</td> -->
            <td>{{ l.treatment_plan_id }}</td>
            <td>{{ l.employee_name }}</td>
            <td :title="l.commentary">
              <i class="fa fa-comment"></i>
            </td>
            <td>
              <div>{{ l.ini_date | dateFormat }}</div>
              <div>{{ l.ini_date | timeFormat }}</div>
              <div>{{ l.end_date | timeFormat }}</div>
            </td>
            <td>
              <span>{{ status(l.status_id).name }}</span>
              <span v-show="[3, 4].includes(l.status_id)">
                - {{ l.updated_at | relativeTime }}</span
              >
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="elModalSetEmp">
      <div class="mb-2">
        <label for="">Doctor</label>
        <SelectEmployee v-model="newEmployeeId"></SelectEmployee>
      </div>
      <button class="btn btn-success" @click="goToAgenda()">
        Continuar
      </button>
    </app-modal-basic>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import { SelectEmployee } from "../../admin-module";

export default {
  components: {
    SelectEmployee
  },

  // directives
  // filters

  props: {
    create: { default: 0 },
    iniDate: {},
    endDate: {},
    employeeId: {},
    date: {},
    treatmentPlanId: {},
    clientId: {}
  },

  data: () => ({
    list: [],
    newEmployeeId: null
  }),

  computed: {
    statusList() {
      return this.$store.state.config.service
        ? this.$store.state.config.service.quotes.status_list
        : {};
    }
  },

  watch: {
    treatmentPlanId() {
      this.$refs.tr.gl();
    },
    clientId() {
      this.$refs.tr.gl();
    }
  },

  mounted() {
    this.$refs.tr.gl();
  },

  methods: {
    goToAgenda() {
      this.$router.push({
        path: `/dental-clinic/weekly-agenda/${this.newEmployeeId}`,
        query: {
          client_id: this.clientId
        }
      });
    },
    status(status_id) {
      return this.statusList[status_id] ? this.statusList[status_id] : {};
    },
    getList(params) {
      return new Promise(rsv => {
        DentalClinicService.getQuotes({
          ...params,
          ini_date: this.iniDate,
          end_date: this.endDate,
          employee_id: this.employeeId,
          date: this.date,
          order: "desc",
          client_id: this.clientId,
          treatment_plan_id: this.treatmentPlanId
        }).then(res => {
          this.list = res.data;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
