<template>
  <div>
    <QuotesList :clientId="client_id"> </QuotesList>
  </div>
</template>
<script>
import QuotesList from "../quotes/List";

export default {
  components: {
    QuotesList
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
